import React from "react";
import { Link } from "gatsby";
import partners from "../../data/partners";

type SponsorsProp = {
  title: string;
};
const Sponsors = ({ title }: SponsorsProp) => {
  return (
    <section>
      <div className="container max-w-screen-xl mx-auto py-20 px-4">
        {title && (
          <h2 className="font-bold font-display text-4xl mb-5">{title}</h2>
        )}
        <div className="gird grid md:grid-cols-6 grid-cols-2 gap-5 mt-5">
          {/* {partners.map((partner) => (
            <Link
              key={partner.name}
              to={partner.url}
              className="p-3 mb-5 flex items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={partner.image} />
            </Link>
          ))} */}
        </div>
      </div>
    </section>
  );
};

export default Sponsors;
