import * as React from "react";
import ButtonLink from "components/ButtonLink";
import ContactForm from "components/ContactForm";
import Hero from "components/Hero";
import Layout from "components/Layout";
import Seo from "components/Seo";
import Sponsors from "components/Sponsors";
import channels from "../data/channels";

const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      description="A community for tech community builders, leaders, managers, developer advocates, and relations within and across Africa."
    />
    <div className="container max-w-screen-xl mx-auto px-6">
      <Hero />
    </div>
    <section className="bg-primary-light">
      <div className="container max-w-screen-xl mx-auto py-20 px-4">
        <h2 className="font-bold font-display text-4xl mb-5">
          Channels of CLA
        </h2>
        <div className="gird grid md:grid-cols-3 grid-cols-1 md:gap-10 gap-6">
          {channels.map((channel) => (
            <div
              key={channel.title}
              className="rounded-large pt-0 pb-10 md:mb-5 mb-0 bg-white"
            >
              <img
                src={channel.img}
                alt={channel.title}
                className="object-fill w-full rounded-t-large"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold font-display">
                  {channel.title}
                </h3>
                <p className="mt-3 font-display text-gray-700 ">
                  {channel.summary}
                </p>
                <div className="mt-3">
                  <ButtonLink
                    type="link"
                    path={`/${channel.title}`}
                    label="Learn more"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <Sponsors title="Brands We've Worked With" />
    <section>
      <div className="container  max-w-screen-xl mx-auto py-20 px-6">
        <ContactForm />
      </div>
    </section>
  </Layout>
);

export default IndexPage;
