import React from "react";
import communityImg from "images/community.png";
import ButtonLink from "components/ButtonLink";
const Hero = () => (
  <div className=" md:grid md:grid-cols-5 grid-cols-1 gap-0  block md:text-left text-center">
    <div className="md:col-span-2 col-span-5">
      <h1 className="leading-tight font-display font-bold md:text-6xl text-4xl mt-24">
        A Community for Community Leaders.
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 297 32"
          width="297"
          height="32"
          preserveAspectRatio="xMidYMid meet"
          style={{
            width: "100%",
            height: "100%",
            transform: "translate3d(0px, 0px, 0px)",
          }}
          className="mt-3"
        >
          <defs>
            <clipPath id="__lottie_element_2">
              <rect width="297" height="32" x="0" y="0"></rect>
            </clipPath>
          </defs>
          <g clipPath="url(#__lottie_element_2)">
            <g
              transform="matrix(1,0,0,1,0,0)"
              opacity="1"
              // style="display: block;"
            >
              <g
                opacity="1"
                transform="matrix(1,0,0,1,148.7209930419922,15.498000144958496)"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fillOpacity="0"
                  stroke="#6E188B"
                  strokeOpacity="1"
                  strokeWidth="4"
                  d=" M-144.7209930419922,-7.426000118255615 C-48.20399856567383,-8.439000129699707 144.72000122070312,-9.647000312805176 144.2779998779297,-9.425999641418457 C144.02200317382812,-9.29800033569336 81.26000213623047,-10.572999954223633 34.14500045776367,-6.010000228881836 C-20.351999282836914,-0.7329999804496765 -60.72100067138672,10.574000358581543 -60.72100067138672,10.574000358581543 C-60.72100067138672,10.574000358581543 14.994000434875488,2.625999927520752 65.78099822998047,2.5739998817443848"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </h1>
      <p className="mt-10 font-display  text-lg text-gray-700">
        A community for tech community builders, leaders, managers, developer
        advocates, and relations within and across Africa.
      </p>
      <div className="mt-10">
        <ButtonLink type="button" path="https://nas.io/cla" label="Join Us" />
      </div>
    </div>
    <div
      className="md:col-span-3 col-span-5 md:p-0 p-5"
      style={{ minHeight: "750" }}
    >
      <img
        src={communityImg}
        alt="Community with different people"
        className=" md:block hidden "
      />
    </div>
  </div>
);

export default Hero;
