import img1 from "images/img1.png";
import img2 from "images/img2.png";
import img3 from "images/img3.png";
import img4 from "images/img4.png";
import img5 from "images/img5.png";
import img6 from "images/img6.png";
interface ChannelsInterface {
  title: string;
  summary: string;
  img: string;
}
const channels: ChannelsInterface[] = [
  {
    title: "Summit",
    img: img1,
    summary:
      "An event for community builders, leaders, managers and, advocates in and within Africa.",
  },
  {
    title: "Festival",
    img: img2,
    summary:
      "A party-like event that brings together community lovers to discuss about the future of community. ",
  },
  {
    title: "Workshop",
    img: img3,
    summary:
      "We run workshops and programs at various cities in Africa to raise credible community leaders. ",
  },
  {
    title: "Project",
    img: img4,
    summary:
      "We run certain projects to provide solutions to challenges face by community leaders and managers.  ",
  },
  {
    title: "Support",
    img: img5,
    summary:
      "We support to community leaders and managers in building their various communities.",
  },
  {
    title: "Consulting",
    img: img6,
    summary:
      "We offer consulting service to company’s looking to launch their community or tech product in Africa ",
  },
];
export default channels;
